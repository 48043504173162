import React from 'react';
import './components.css';
import {Link} from 'react-router-dom';

function Hservices(props) {
    return(

        <center className="col-10 mx-auto col-md-6 col-lg-3 my-3">
        <Link style={{textDecoration:"none"}} to={`/service/${props.id}`}>
        <div className="card" style={{width: "15rem"}}>
          <img className="card-img-top" src={props.img} alt="Home Services" />
          <div className="card-body">
            <h5 className="card-text text-center">{props.title}</h5>
          </div>
        </div>
        </Link>
        </center>

        )
}


export default Hservices;



