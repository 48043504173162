import React from 'react';
import './pages.css';

export default function About(){
    return(
        <div className="Aboutus" id="hero">
        <div className="about">

        <div className="row">
        <div className="col">
        </div>
            <div className="col cardCollection">
                <div className="cardss card1">
                </div>
                <div className="cardss card2">

                </div>
                <div className="cardss card3">

                </div>
                <div className="cardss card4">

                </div>
            </div>
        </div>
        </div>

        <i><h2 className="text-center mx-auto my-5" style={{color:"crimson"}}>Nirman Sathi - Know Us Better</h2></i>

        <div className="section container mt-2">

            <div className="aboutcontainer">
            <div className="aboutpageinfo">
                <h2 className="mx-auto text-center">About Nirman Sathi</h2>
            </div>

            <div className="aboutpagedetail">
                <div className="detailbox">
                  <img src="./pictures/aboutpic.png" alt="about illustration" />
                <p className="aboutpara">Nirman Sathi is recognized as the fastest-growing startup in Nepal. We are a unique web marketplace for home services. <br /> <br />It’s very difficult to find the best employee at the reasonable cost by trusted service provider. We provide home services which consist of Plumbing, Electrical, Carpentry, Painting, Appliance repairs & Maintenance. We also provide Interior Design, Construction, Renovation, Seepage treatment, Vaastu counselling and more than 100 home services just in a click. We are a sure shot destination for your service needs. <br/ ></p>
                </div>
            </div>
            </div>

            <div className="aboutcontainer">
            <div className="aboutpageinfo">
                <h2 className="mx-auto text-center">Story behind Nirman Sathi</h2>
            </div>

            <div className="aboutpagedetail">
                <div className="detailbox">
                  <img src="./pictures/aboutpic1.png" alt="about illustration" />
                <p className="story mt-2">We often saw in the field of Engineering & Construction, it’s a very difficult job to find the best employee at a reasonable cost with the greatest quality by the trusted service provider. Most of the time throughout our engineering business, we realized gap in terms of reliability, convenience, responsiveness, quality etc. Then we decided to emerge nirmansathi.com to facilitate over these worries.</p>
                </div>
            </div>
            </div>
        </div>

        <i><h2 className="text-center mx-auto mt-5" style={{color:"crimson"}}>Nirman Sathi - Driven Towards Service</h2></i>

        <section className="ourCollection">

        <div className="ourcards">
            <div className="ourbox">
                <div className="imgBox">
                    <img src="./pictures/our mission1.jpg" alt="our mission" />
                </div>
                <div className="contentBx">
                    <div>
                        <h3>Our Mission</h3>
                        <p>To make Building Construction, Detailed Engineering, Repair, Maintenance & Building Health services Easy, Reliable, Responsive & Hassle-free</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="ourcards">
            <div className="ourbox">
                <div className="imgBox">
                    <img src="./pictures/our values1.jpg" alt="our mission" />
                </div>
                <div className="contentBx">
                    <div>
                        <h3>Our Values</h3>
                        <p>To create an exclusive online & offline platform and opportunities for formally unorganized & unemployed, underpaid skilled workers. Coming together is a beginning & working together is success.</p>
                    </div>
                </div>
            </div>
        </div>

      <div className="ourcards">
            <div className="ourbox">
                <div className="imgBox">
                    <img src="./pictures/our hope.jpg" alt="our mission" />
                </div>
                <div className="contentBx">
                    <div>
                        <h3>Our Hope</h3>
                        <p>To build up lifelong relationship with our clients and always provides the best services. Doing business get better when we are together.</p>
                    </div>
                </div>
            </div>
        </div>

        </section>

            <section className="home saying" style={{marginTop:"8%"}}>
            <div className="container" style={{fontFamily:"Alegreya"}}>
            <div className="col-10 col-md-10 text-center m-auto d-block mt-sm-5">
            <h5 className="mt-5" style={{fontWeight:"bold",fontFamily:"sans-seriff"}}>"Nirman-Sathi is running with this aim to provide the best engineering services to our clients. We deal in all small and all major works on visiting and contract basis."</h5>
            <figure className="figure">
            <img src="../pictures/chairman1.png" className="figure img-fluid rounded-circle mt-3 mb-1" alt="chairman"
                height="130px" width="130px" />
            </figure>
            <figcaption>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/nirmansathinp/"><i className="mb-2 mt-1 fa fa-linkedin fa-lg" aria-hidden="true" style={{color:"#00acee"}}></i></a>
            <h3> Kumar Bhatta </h3>
            <h5 className="figure-caption" style={{fontSize:"20px"}}> C0-FOUNDER, CEO </h5>
            <h5 className="figure-caption mb-4" style={{fontSize:"18px"}}>Nirman Sathi</h5>
            </figcaption>
            </div>
            </div>
            </section>


        </div>
        )
}
