import React from 'react';
import './components.css';

export default function Client(props) {
    return(
        <div className="col-md-4 cli text-center mx-auto my-3">
        <div className="profile">
        <img src={props.img} alt="home client" className="user" />
        <blockquote><q>{props.quote}</q></blockquote>
        <h5>{props.name}</h5>
        <h6>{props.title}</h6>
        <div className="mx-auto">
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star"></span>
        </div>
        </div>
        </div>
        )
}
