import React from 'react';
import './components.css';
import {Link} from 'react-router-dom';
import ContactForm from './ContactForm.js';


export default class ServiceD extends React.Component{
  state = {
    showModal : false,
  }

    render(){

      let modalClose = () => {
        this.setState({
          showModal: false,
        })
      }

        const {detail ,id} = this.props;
        return(
            <div>
            {detail.filter(det => det.id == id).map((data,ind) => (
              <div className="wrapper mt-2">

              <div className="left-col">
                <div className="content mt-4">
                  <h3 className="mx-auto text-center">{data.item}</h3>
                  <h6 className="mx-auto text-center">We provide best service for <strong>{data.item}</strong></h6>
                  <div className="row d-flex justify-content-around">
                  <button className="mt-2 mb-5 btn" onClick={()=> this.setState({showModal:true})} style={{backgroundColor:"crimson",color:"white",borderRadius:"20px",padding:"10px 20px 10px 20px",cursor:"pointer"}} type="button"><b>BOOK NOW</b></button>
                  <ContactForm show={this.state.showModal} onHide={modalClose} />
                  <Link to="/service"><button className="backbtn mt-2 mb-5  btn btn-primary" style={{color:"white",borderRadius:"20px",padding:"10px 20px 10px 20px",cursor:"pointer"}} type="button"><b>GO BACK</b></button></Link>
                    <h4 className="serve mt-5"><strong> <span style={{color:"blue"}}>Huge Selection</span> | Reasonable Cost | <span style={{color:"blue"}}> Fast Service </span></strong></h4>
                  </div>
                </div>
              </div>

              <div className="right-col">
                <div className="sliderr">
                    <img src={data.photo} alt="detail service" />
                </div>

                <div className="slider-content" style={{backgroundColor:"#f5f8fc"}}>
                  <h4>Category: {data.category}</h4>

                  <div id="carouselExampleControls" data-interval="2400" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                  <div className="carousel-item active">
                  <p>{data.detail}</p>
                  </div>
                  <div className="carousel-item">
                  <p>{data.detail1}</p>
                  </div>
                  <div className="carousel-item">
                  <p>{data.detail2}</p>
                  </div>

                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <div className="controller">
                      <i className="fa icons fa-chevron-left fa-lg" id="BackBtn" aria-hidden="true" style={{color:"crimson"}}></i>
                        </div>
                    </a>

                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <div className="controller">
                      <i className="fa icons fa-chevron-right fa-lg" id="NextBtn" aria-hidden="true" style={{color:"crimson"}}></i>
                      </div>
                    </a>


                  </div>
                  </div>

                </div>

              </div>

              </div>

            ))}
            </div>

          )
  }
}






