import React from 'react';
import './pages.css';
import ServiceData from '../Data/ServiceData.js';
import ServiceList from '../components/ServiceList.js';
import SubService from '../components/subservicelist.js';


export default class ServicePage extends React.Component{
    state ={
        services: ServiceData,
    }

     render(){
        const { services } = this.state;
        return(
        <div className="ServicePage" id="hero">
        <div className="container">
        <h2 className="mt-2 mb-3 serve mx-auto text-center">Services We Provide</h2>
        <div className="row flowFromRight">


                    {services.map((item) => (
                        <div class="col-sm-12 col-md-6 mt-2 mb-2 serviceCard" >
                           <SubService subservicelist={item.services} />
                           <ServiceList kaam={item} />
                       </div>
                    ))}


        </div>

        <div>
        <h4 className="mx-auto mt-4 mb-4 text-center serve"> <a style={{textDecoration:"none",color:"crimson"}} href = "#other"> Other Services <span><i className="fa fa-caret-down" aria-hidden="true"></i></span> </a> </h4>
            <p id="other">
            <div className="row butto mx-auto">
              <a className="col-lg-2 col-md-6 col-10 btn btn-danger mt-4 mr-lg-5 mr-md-4" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Home Improvements <span><i className="fa fa-caret-down" aria-hidden="true"></i></span></a>
              <button className="col-lg-2 col-md-6 col-10 btn btn-danger mt-4 mr-lg-5 mr-md-4" type="button" data-toggle="collapse" data-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2">Construction <span><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <button className="col-lg-2 col-md-6 col-10 btn btn-danger mt-4 mr-lg-5 mr-md-4" type="button" data-toggle="collapse" data-target="#multiCollapseExample3" aria-expanded="false" aria-controls="multiCollapseExample3">Design & Engineering <span><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <button className="col-lg-2 col-md-6 col-10 btn btn-danger mt-4 mr-lg-5 mr-md-4" type="button" data-toggle="collapse" data-target="#multiCollapseExample4" aria-expanded="false" aria-controls="multiCollapseExample4">Building Vastu <span><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
            </div>
            </p>
            <div className="row">

              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <div className="collapse multi-collapse" id="multiCollapseExample1">
                <div className="list-group">
                   <a href="/" className="list-group-item list-group-item-action active">We provide following Home Improvements Services:</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Cladding Installation</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Crack Inspection</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Door Repair</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Fencing</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Garage Installation</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">House Remodel</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Site Clearance</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">House Repair</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Artifial Turfing</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Demolition Services</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Excavation Services</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Smart Home System</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Swimming Pool Inspection</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Water Proofing</a>
                </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <div className="collapse multi-collapse" id="multiCollapseExample2">
                <div className="list-group">
                    <a href="/" className="list-group-item list-group-item-action active">We provide following Construction Services:</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">New House Construction</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Home Remodeling</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Retrofitting</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">New Painting Works</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Landscaping</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Swimming Pool</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">General Contracting</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Cottages</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Home Addition</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Renovation</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Gardens</a>
                </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <div className="collapse multi-collapse" id="multiCollapseExample3">
                <div className="list-group">
                    <a href="/" className="list-group-item list-group-item-action active">We provide following Design & Engineering Services:</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Interior Design</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Architectural design</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Space Planning</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Surveying</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Structural Design</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Crack Inspection</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Layout</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Elevation Design</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Estimation</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Site Supervision</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Plotting</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">3D Modeling</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Land Measurement</a>
                </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <div className="collapse multi-collapse" id="multiCollapseExample4">
                <div className="list-group">
                <a href="/" className="list-group-item list-group-item-action active">We provide following Building Vastu Services:</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Exterior Vastu</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Interior Vastu</a>
                   <a className="list-group-item list-group-item-action li text-white" href="/">Vastu Consultant</a>
                </div>
                </div>
              </div>

            </div>

        </div>

        </div>
        </div>
        )
    }
}


