import React from 'react';
import './pages.css';
import HServices from '../components/HServices.js';
import WhyNirman from '../components/whynirman.js';
import Associates from '../components/Associates.js';
import Client from '../components/Clients.js';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import {Link} from 'react-router-dom';

export default class Home extends React.Component{
    render(){
        return(
            <section className="home" id="hero">

            <div className="container-fluid">
            <div className="row">
            <div className="col-10 mx-auto my-lg-4">
            <div className="row">

            <div className="col-md-6 pt-5 home-quote">
            <h3 className="my-3">Come up with<span style={{color:"crimson"}}> Happiness </span></h3>
            <strong><h3 className="mb-4" style={{color:"crimson"}}><span style={{color:"blue"}}>Reasonable Cost,</span> Fast Service</h3></strong>
            <h1 className="my-2">Remember<strong style={{color:"crimson"}}> Nirman Sathi</strong> for more than <span style={{color:"blue"}}> 100 Home </span> services</h1>
            <div className="mt-3">
            <Link to="/service"><button className="get-started">Book Service</button></Link>
            </div>
            </div>

            <div className="col-lg-6 header-img">
            <img src="./pictures/hero-image.png" className="img-fluid" alt="hero" />
            </div>
            </div>

            <center className="row mx-auto" style={{marginTop:"90px"}}>

            <div className="col-lg-3 col-md-6 col-sm-12 d-block mx-auto-auto">
            <Link to="/service">
            <figure className="figure">
              <img src="./pictures/vastu.png" className="figure-img img-fluid rounded" alt="fig" height="80px" width="80px"/>
              <figcaption className="figure-caption text-dark">
              <h6>Engineering & Vastu</h6>
              </figcaption>
            </figure>
            </Link>
            </div>


            <div className="col-lg-3 col-md-6 col-sm-12 d-block m-auto">
            <Link to="/service">
            <figure className="figure">
              <img src="./pictures/plumbing.png" className="figure-img img-fluid rounded" alt="fig" height="80px" width="80px"/>
              <figcaption className="figure-caption text-dark">
              <h6>Home Improvements</h6>
              </figcaption>
            </figure>
            </Link>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 d-block m-auto">
            <Link to="/service">
            <figure className="figure">
              <img src="./pictures/repair.png" className="figure-img img-fluid rounded" alt="fig" height="80px" width="80px"/>
              <figcaption className="figure-caption text-dark">
              <h6>Appliance Repair</h6>
              </figcaption>
            </figure>
            </Link>
            </div>



            <div className="col-lg-3 col-md-6 col-sm-12 d-block m-auto">
            <Link to="/service">
            <figure className="figure">
              <img src="./pictures/const & reno.png" className="figure-img img-fluid rounded" alt="fig" height="80px" width="80px"/>
              <figcaption className="figure-caption text-dark">
              <h6>Construction & Renovation</h6>
              </figcaption>
            </figure>
            </Link>
            </div>


            </center>

            </div>
            </div>
            </div>




            <div className="services home" style={{marginTop:"8%"}}>
            <div className="container">
            <h2 className="mx-auto serve text-center mb-5">Our Services</h2>

            <h4 className="mb-4">Home Decor & Design <span><i className="fa fa-home fa-lg" aria-hidden="true"></i></span></h4>
            <div className="row">
            <HServices title="Modular Kitchen" img="./pictures/services-kitchen.jpg" id="17" />
            <HServices title="Interior Design" img="./pictures/services-interior.png" id="11" />
            <HServices title="3D-Modeling" img="./pictures/services-3d.jpg" id="9" />
            <HServices title="Architectural Design" img="./pictures/services-architecture.jpg" id="7" />
            </div>

            <h4 className="mt-5 mb-4">Home Repair <span><i className="fa fa-home fa-lg" aria-hidden="true"></i></span></h4>
            <div className="row">
            <HServices title="Electrical Works" img="./pictures/services-electrical.png" id="14" />
            <HServices title="Painting Works" img="./pictures/services-paint.png" id="16" />
            <HServices title="Appliance Repair" img="./pictures/services-app.jpg" id="18" />
            <HServices title="Plumbing Works" img="./pictures/services-plumbing.png" id="15" />
            </div>

            <h4 className="mt-5 mb-4">Recommended Services</h4>
            <div className="row">
            <HServices title="AC-Repair" img="./pictures/services-acrepair.png" id="18" />
            <HServices title="Seepage" img="./pictures/seepage.jpg" id="24" />
            <HServices title="Carpentry" img="./pictures/services-carpenter.png" id="17" />
            <HServices title="Metal Works" img="./pictures/services-metal.png" id="17" />
            </div>

            </div>
            </div>


            <div className="pros home" style={{marginTop:"10%"}}>
            <div className="container mx-auto">
            <h2 className="mx-auto serve text-center mb-5">Why Nirman Sathi <span><i className="fa fa-question-circle fa-lg" aria-hidden="true"></i></span></h2>
            <center className="row mx-auto d-flex">
            <WhyNirman title="In-house technician" img="./pictures/why1.png" />
            <WhyNirman title="Hassle Free Service" img="./pictures/why2.png" />
            <WhyNirman title="100% Productive Work" img="./pictures/why3.png" />
            <WhyNirman title="Standard Pricing" img="./pictures/why4.png" />
            <WhyNirman title="Direct Talking" img="/pictures/talking.png" />
            </center>
            </div>
            </div>



            <div className="associates home" style={{marginTop:"8%"}}>
            <div className="container">
            <h2 className="mx-auto serve text-center mb-5">Our Associates <span><i className="fa fa-handshake-o fa-lg" aria-hidden="true"> </i> </span></h2>
            <div className="row slic">
            <Associates img="./pictures/associate1.png" />
            <Associates img="./pictures/associate2.jpg" />
            <Associates img="./pictures/associate3.jpg" />
            <Associates img="./pictures/associate4.png" />
            <Associates img="./pictures/associate5.png" />

            </div>
            </div>
            </div>


            <div className="clients home mb-5" style={{marginTop:"10%"}}>
            <div className="container">
            <h2 className="mx-auto serve client text-center mb-5">Client's Reviews</h2>
            <div className="row mt-5">

            <Client img="./pictures/client1.jpg" quote="I strongly recommend Nirman-Sathi as a construction consultant." name="Mr. Marichman Shrestha" title="Owner, Balchhi-Dhurbe Futsal" />
            <Client img="./pictures/client2.png" quote="It's been a great experience working with Nirman-Sathi." name="Mr. Bijan Duwadi" title="Managing Director, Citizens Fm 97.5" />
            <Client img="./pictures/client3.jpg" quote="Reasonably priced, no overcharging, satisfactory services and punctual people." name="Mr. Rabin P. Sharma" title="Global College International - GCI" />

            </div>
            </div>
            </div>


            <div className="counter home" style={{marginTop:"10%",backgroundColor:"#f5f8fc"}}>
            <div className="container">
            <h2 className="mx-auto serve text-center mt-2 mb-4">2 Years of Experience</h2>
            <h5 className="mx-auto text-center">We are happy to serve our clients <span><i className="fa fa-smile-o" aria-hidden="true"></i></span></h5>
            <div className="row my-4">

            <div className="increase col-lg-4 col-md-10 col-sm-10 my-5 mx-auto">
            <h3 className= "text-center">Total Quotation <span><i className="fa fa-users" aria-hidden="true"></i></span></h3>
            <div className="counter text-center" style={{fontSize:"30px"}}>
            <CountUp end={12651} redraw={true}>
                {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
            </CountUp>
            </div>
            </div>

            <div className="increase col-lg-4 col-md-10 col-sm-10 my-5 mx-auto">
            <h3 className= "text-center">Awards Won <span><i className="fa fa-trophy" aria-hidden="true"></i></span> </h3>
            <div className="counter text-center" style={{fontSize:"30px"}}>
            <CountUp end={4} redraw={true}>
                {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
            </CountUp>
            </div>
            </div>

            <div className="increase col-lg-4 col-md-10 col-sm-10 my-5 mx-auto">
            <h3 className= "text-center">Work Done <span><i className="fa fa-briefcase" aria-hidden="true"></i></span></h3>
            <div className="counter text-center" style={{fontSize:"30px"}}>
            <CountUp end={3571} redraw={true}>
                {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
            </CountUp>
            </div>
            </div>

            </div>
            </div>
            </div>


            </section>
            )
    }
}
