import React from 'react';
import '../components/components.css';
import {Link} from 'react-router-dom'

function Footer() {
    return (
        <div>
             <footer className="full-footer text-center" style={{marginTop:"6%",backgroundColor:"#f5f8fc"}} >
                        <a href = "#hero">
                            <i className="fa fa-chevron-up fa-1x mb-2" style={{borderRadius:"50%", padding:"20px",backgroundColor:"lightgray"}} aria-hidden="true"></i>
                        </a>
                <div className="container-fluid mt-3 top-footer p-md-2 p-1">
                    <div className="row">
                        <div className="col-md-4 pt-2">
                            <img className="img-fluid" src="../pictures/footerlast.png" alt="foot" width="70%" />
                            <p className="mt-2"><span><i className="fa fa-phone fa-lg" aria-hidden="true" style={{color:"green"}}></i></span>  01-4467412, 9841811623, 9816591659</p>
                            <p className="pt-1"><span><i class="fa fa-map-marker fa-lg" aria-hidden="true" style={{color:"red"}}></i></span>  Corporate Office, Old Baneshwor, Nepal</p>
                        </div>

                        <div className="col-md-2">
                            <h4 className="pr-2">Quick Links</h4>
                                <div className="footer-link" style={{display:"flex",flexDirection:"column"}}>
                                        <Link className="pt-3 pl-4" style={{color:"black"}} to="/service">Services</Link>
                                        <Link className="mr-3 pt-2 pl-4"style={{color:"black"}} to="/about">About Us</Link>
                                        <Link className="mr-3 pt-2 pl-4"style={{color:"black"}} to="/privacy-policy">Privacy & Policy</Link>
                                        <Link className="mr-3 pt-2 pl-4"style={{color:"black"}} to="/terms-and-conditions">Terms & Conditions</Link>
                                </div>
                        </div>

                        <div className="col-md-3 mt-5 pt-3">
                            <div className="d-flex mx-auto">
                            <a className="px-3" target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/NirmanSathiNp/"><i className="fa fa-facebook-official fa-2x" aria-hidden="true" style={{color:"#3b5998"}}></i></a>
                            <a className="px-3" target="_blank" rel="noopener noreferrer" href="https://twitter.com/NirmanSathiNp"><i className="fa fa-twitter fa-2x" style={{color:"#00acee"}} aria-hidden="true"></i></a>
                            <a className="px-3" target="_blank" rel="noopener noreferrer" href="mailto:mail@nirmansathi.com"><i className="fa fa-envelope fa-2x" aria-hidden="true" style={{color:"#D44638"}}></i></a>
                            <a className="px-3" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/nirmansathinp/"><i className="fa fa-linkedin fa-2x" aria-hidden="true" style={{color:"#00acee"}}></i></a>
                            <a className="px-3" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCj66hePBGaCMxwQ3-8gethg"><i class="fa fa-youtube fa-2x" style={{color:"crimson"}} aria-hidden="true"></i></a>
                            </div>
                            <div className="copyright pt-4">
                                 <i className="fa fa-copyright fa-sm" aria-hidden="true" style={{color:"black"}}> copyright 2021 Nirman Sathi.</i>
                                 <p className="mt-3">Designed and Developed by <a rel="noopener noreferrer" href="https://metamium.com/" target="_blank" style={{textDecoration:"none"}}> Metamium Group</a></p>
                         </div>
                        </div>

                        <div className="col-md-3">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2055.451189412217!2d85.34096282613906!3d27.700670415470093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb196d082cc749%3A0x29c3a4d4737b6a58!2sNirman-Sathi!5e0!3m2!1sen!2snp!4v1610275699210!5m2!1sen!2snp" title="nirmansathi-Location" width="250" height="250" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0" />
                        </div>

                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
