import React from 'react'
import './components.css';
import {Modal, Button} from 'react-bootstrap';
import fire from '../Firebase.js';

export default class ContactForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            full_name : "",
            email : "",
            contact_number : "",
            address : "",
            date_and_time : "",
            message : "",
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const db = fire.firestore();
          db.settings({
            timestampsInSnapshots: true
          });
          db.collection("Nirman Sathi Service Booking Data").add({
          full_name : this.state.full_name,
          email: this.state.email,
          contact_number: this.state.contact_number,
          address: this.state.address,
          date_and_time: this.state.date_and_time,
          message: this.state.message
        })
        .then(alert("Thank you, We will get back to you soon"))
        .catch(err => alert("Something went wrong, Please try again"))

        this.setState({
            full_name : "",
            email : "",
            contact_number : "",
            address : "",
            date_and_time : "",
            message : "",
        })
}




    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }



    render(){
        return(
            <div>
            <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter">
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{color:"crimson"}}>
                  Service Booking Form
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div>
                <form className="book" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label for="full name" className="col-form-label">Full Name:</label>
                    <input type="text" value={this.state.full_name} name="full_name" onChange={this.handleChange} placeholder="Enter your full name" className="form-control" id="name" required />
                  </div>
                  <div className="form-group">
                    <label for="email" className="col-form-label">Email Address:(optional)</label>
                    <input type="email" value={this.state.email} onChange={this.handleChange} name="email" placeholder="Enter your valid email address" className="form-control" id="email" />
                  </div>
                  <div className="form-group">
                    <label for="Phoneno" className="col-form-label">Contact Number:</label>
                    <input type="tel" value={this.state.contact_number} onChange={this.handleChange} name="contact_number" className="form-control" id="phone" placeholder="Enter your mobile or landline number" id="contact" pattern="[0-9]{9,10}" required />
                  </div>
                  <div className="form-group">
                    <label for="address" className="col-form-label">Current Address:</label>
                    <input type="text" value={this.state.address} onChange={this.handleChange} name="address" placeholder="Enter your current Address" className="form-control" id="address" required />
                  </div>
                  <div className="form-group">
                    <label for="date&time" className="col-form-label">Date & Time:</label>
                    <input type="datetime-local" value={this.state.date_and_time} onChange={this.handleChange} name="date_and_time" placeholder="Provide your required date" className="form-control" required />
                  </div>
                  <div className="form-group">
                    <label for="message-text" className="col-form-label">Message:</label>
                    <textarea className="form-control" onChange={this.handleChange} value={this.state.message} name="message" placeholder="Descibe your problem in details(eg: plumber,electrician,etc)" id="message-text" required></textarea>
                  </div>
                    <div className="d-flex" style={{marginTop:"8px"}}>
                        <Button className="btn btn-primary" onClick={this.props.onHide}>Close</Button>
                        <input type="submit" value="Submit" className="btn btn-danger ml-3" />
                    </div>
                </form>
              </div>
            </Modal.Body>
            </Modal>
            </div>
            )
    }


}





