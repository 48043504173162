const ServiceData = [

    {
        title : "Construction",
        main_img :"/pictures/construction.png",
        services: [
            {
            id : 1,
            item: "RCC/Load Bearing Structure",
            category :"Construction",
            photo : "/pictures/load.jpg",
            detail: "Reinforced Cement Concrete (RCC) frame structures are a very common type of modern building internationally.  As the name suggests, this type of building consists of a frame or skeleton of concrete.  Horizontal members of this frame are called beams, and vertical members are called columns.",
            detail1: "Of these the column is the most important as it is the primary load-carrying element of the building. If you damage a beam or slab in a building this will affect only one floor but damage to a column could bring down the entire building.",
            detail2:"Load bearing masonry (Wall system) construction was the most widely used form of construction for large buildings. It is very rarely used today for large buildings, but smaller residential-scale structures are being built.",
            },

            {
            id : 2,
            item: "Steel Structure",
            category :"Construction",
            photo : "/pictures/load.jpg",
            detail: "The steel structure is reliable and requires less raw materials than other types of structure like concrete structure and timber structure. In modern construction, the steel structure is used for almost every type of structure including heavy industrial buildings, multi-storey buildings as well as simple residential and commercial buildings.",
            detail1: "This immense strength is of great advantage to buildings.  The other important feature of steel framing is its flexibility.  It can bend without cracking, which is another great advantage, as a steel building can flex when it is pushed to one side by say, wind, or an earthquake.  ",
            detail2: "In this modern age, the steel structure is highly recommended in a developing country like Nepal. It has a fast building process with full strength. Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic and safe steel structures to our respected clients within Agreed time.",
            },

            {
            id : 3,
            item: "Wooden Structure",
            category :"Construction",
            photo : "/pictures/load.jpg",
            detail: "Light wood framed construction is one of the most popular types of building methods for building. It is light and allows quick construction with no heavy tools or equipment. Every component can easily be carried by hand – a house essentially becomes a large carpentry job. The main tool is a handheld nail gun. It can adapt itself to any geometric shape and can be clad with a variety of materials.",
            detail1: "Wooden buildings are durable, they age gracefully, and they are reasonably easy to maintain and change. Wood may be chosen as a structural or construction material both for sustainability and cost reasons, or to fulfil a design idea that seeks to exploit the interplay between the structure and the architectural expression.",
            detail2: "Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic and safe wooden structures to our respected clients within Agreed time.",
            },

            {
            id : 4,
            item: "Bamboo Huts/Cottages",
            category :"Construction",
            photo : "/pictures/load.jpg",
            detail: "Bamboo is an alternative that is not only environmentally friendly, but it’s easy on your wallet too. Bamboo homes are attractive and offer many benefits to homeowners. Bamboo is flexible, so you can bend it without fear of splintering or breaking. Bamboo homes are also naturally earthquake resistant.",
            detail1:"Bamboo produces more oxygen than traditional wood. It absorbs more carbon dioxide than other plants and releases more super-oxygenated air. After reading about the benefits of bamboo for construction, you may want to consider this option for designing your dream home. ",
            detail2:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic and safe bamboo huts and cottages to our respected clients within Agreed time.",
            },

            {
            id : 5,
            item: "Swimming Pool",
            category :"Construction",
            photo : "/pictures/load.jpg",
            detail: "A swimming pool is a structure designed to hold water to enable swimming or other leisure activities. Pools can be built into the ground or built above ground as a freestanding construction or as part of a building or other larger structure and are also a common feature aboard ocean-liners and cruise ships. Many health clubs, fitness centres and private clubs have pools used mostly for exercise or recreation. Many towns and cities provide public pools",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in allied sector. We provide economic, safe & attractive swimming pools to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id : 6,
            item: "Parks/Garden",
            category :"Construction",
            photo : "/pictures/load.jpg",
            detail: "A park is an area of natural, semi-natural or planted space set aside for human enjoyment and recreation or for the protection of natural habitats. It may consist of grassy areas, rocks, soil and trees, but may also contain buildings and other artefacts such as monuments, fountains or playground structures. Other common features of parks include playgrounds, gardens, hiking, running and fitness trails or paths, bridle paths, sports fields and courts, public restrooms, boat ramps, and/or picnic facilities, depending on the budget and natural features available.",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

        ],
    },

    {
        title : "Design & Engineering",
        main_img :"/pictures/engineering.png",
        services: [
            {
            id : 7,
            item: "Architectural Design",
            category :"Design & Engineering",
            photo : "/pictures/load.jpg",
            detail: "Dedicated Architecture & Engineers for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 8,
            item: "Structural Design",
            category :"Design & Engineering",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 9,
            item: "3D Modeling",
            category :"Design & Engineering",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 10,
            item: "Planning / Plotting",
            category :"Design & Engineering",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 11,
            item: "Interior Design",
            category :"Design & Engineering",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 12,
            item: "Estimation & Supervision",
            category :"Design & Engineering",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 13,
            item: "Land Measurement & Surveying",
            category :"Design & Engineering",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },
        ]
    },


    {

        title :"Home Improvements",
        main_img :"/pictures/r&m.png",
        services: [
            {
            id : 14,
            item: "Electrical Works",
            category :"Home Improvements",
            photo : "/pictures/load.jpg",
            detail: "Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 15,
            item: "Plumbing Works",
            category :"Home Improvements",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 16,
            item: "Flooring & Painting Works",
            category :"Home Improvements",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 17,
            item: "Wooden & Metal Works",
            category :"Home Improvements",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 18,
            item: "AC Repair & Services",
            category :"Home Improvements",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 19,
            item: "CCTV Installation",
            category :"Home Improvements",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },
        ]

    },



    {

        title :"Seepage Treatment",
        main_img :"/pictures/building.png",
        services: [
            {
            id : 20,
            item: "Seepage Treatment",
            category :"Seepage Treatment",
            photo : "/pictures/load.jpg",
            detail: "We Break the damaged plaster, Apply a waterproof coating agent ,Replaster the exposed patch, Repaint the area after base preparation ,Treating Severe Dampness in Interior Walls,Treating Mild Dampness in Interior Walls,Waterproofing Tile Cracks & Grout in Bathroom or Kitchen",
            detail1:"Treating Mild Dampness on Adjacent Wall,Treating Severe Dampness on Adjacent Wall,Repairing & Waterproofing Exterior Wall,Treating Mild Dampness in Interior Wall,Treating Severe Dampness in Interior Wall,Roof Waterproofing method,Waterproofing Solution for Kitchen or Bathroom,Repairing & Waterproofing Exterior Walls,Filling Cracks between Tiles & Waterproofing Grout",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 21,
            item: "Settlement Inspection",
            category :"Seepage Treatment",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {
            id: 22,
            item: "Water Profing",
            category :"Seepage Treatment",
            photo:"/pictures/load.jpg",
            detail:"Dedicated Project Manager for assistance & hassle-free experience. Get free quotations from best in class professionals. Our experts will visit your property, take the measurements, understand usage & then suggest the best designs,They will show you designs from previous projects for better understanding ",
            detail1:"Nirman-Sathi has well trained, experienced and highly skilled technicians in the allied sector. We provide economic, safe & attractive parks to our respected clients within Agreed time.",
            detail2:"Welcome to the World of Convenience",
            },

            {

            },
        ]
    },



]



export default ServiceData;

