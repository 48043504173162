import React from 'react';
import './pages.css';
import ServiceD from '../components/ServiceD.js';
import ServiceData from '../Data/ServiceData.js';


export default class ServiceDetail extends React.Component{
    constructor(props){
        super(props);
        const idm = this.props.match.params.id;
            this.state = {
                servicess : ServiceData,
                idm
            }
    }

    render(){
        return(
            <div className="container-fluid">
            <div>
                    {this.state.servicess.map((item) => (
                        <div>
                           <ServiceD id={this.state.idm} detail={item.services} />
                       </div>
                    ))}
            </div>
            </div>
            )
    }

}
