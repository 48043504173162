import React from 'react';
import './components.css';




export default class ServiceList extends React.Component{

    render(){
        const {kaam} = this.props;
        return(
            <div className="card" style={{width:"84%",backgroundColor:"#f5f8fc"}}>
                <div className="sercice-imgContainer">
                    <img className="ph" src={kaam.main_img} alt="Service title Pictures" />
                </div>
                <div className="card-body">
                    <h5 className="card-title text-center">{kaam.title}</h5>
                </div>
            </div>

        );
    }
}






