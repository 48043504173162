import React from 'react';
import './components.css';

function Associates(props) {
    return(
        <div className="col-10 mx-auto col-md-6 col-lg-2 my-3">
        <img src={props.img} alt="Associates" className="img-thumbnail" />
        </div>
        )
}


export default Associates;

