import React from 'react';
import './pages.css';

export default function Termsandconditions(){
    return(
        <div className="container">
        <h2 className="mx-auto serve text-center" id="hero">Terms and Conditions</h2>
        <div className="mx-auto tnc mt-5">
                <p>PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE PLATFORM. THESE TERMS CONSTITUTE A BINDING, LEGAL
                    AGREEMENT BETWEEN YOU AND THE COMPANY. IN ORDER TO USE THE PLATFORM AND/OR AVAIL OF THE SERVICES,
                    YOU MUST AGREE TO THESE TERMS. IF YOU DO NOT AGREE WITH THESE TERMS IN THEIR ENTIRETY, YOU ARE NOT
                    ENTITLED TO USE THE PLATFORM OR AVAIL THE SERVICES PROVIDED BY US.</p>
                <p>This Terms of Use Agreement was last updated: February 27, 2019. This Terms of Use agreement is
                    effective by the same date. These terms of use govern your (hereinafter be mentioned too as “You”,
                    “Your”, “Client” as appropriate) access and/or use of:</p>
                <ul>
                    <li>nirmansathi.com</li>
                    <li>All related widgets, tools, applications, data, software, APIs [Collectively the “Platform”];
                        and</li>
                    <li>Services (As defined hereinbelow) provided by nirmansathi.com</li>
                </ul>
                <p>This Website is owned and operated by Nirmansathi Technovation Pvt.Ltd.
                    Having corporate office Old Baneshwor, Kathmandu. (“Company”, “Nirman-Sathi”, “Nirmansathi.com”,
                    “We”, “Us”, “Our” and terms of similar meaning).</p>
                <p>You acknowledge that the Website serves as a venue for the online distribution and publication of
                    user-submitted information between Service Professionals and Service Users, and, by using, visiting,
                    registering for, and/or otherwise participating in this Website, including the Services presented,
                    promoted, and displayed on the Website, and by clicking on “I have read and agree to the terms of
                    use,” you hereby certify that:</p>
                <ul>
                    <li>you are either a Service Professional or a prospective Service User.</li>
                    <li>you have the authority to enter these Terms of Use</li>
                    <li> you authorize the transfer of payment for Services requested using the Website, and</li>
                    <li> you agree to be bound by all terms and conditions of these Terms of Use and any other documents
                        incorporated by reference herein.</li>
                </ul>
                <p>If you do not so agree to the foregoing, you should not click to affirm your acceptance thereof, in
                    which case you are prohibited from accessing or using the Website. If you do not agree to any of the
                    provisions set forth in the Terms of Use, kindly discontinue viewing or participating in this
                    Website immediately.</p>
            </div>
            <div class="item">
                <h3 class="about-small-title small-title">
                    <span class="title">PLATFORM AND SERVICES</span>
                </h3>
                <p>The Platform is a web-based platform operated by the Company, that enlists various engineering and
                    related services enabling the Clients to browse and avail services, subject to these Terms (As
                    defined hereinbelow).</p>
                <p>The Company is in the business of providing the following services through the Platform:</p>
                <ul>
                    <li>Booking of services on the Platform.</li>
                    <li>Applying for careers on the platform.</li>
                    <li>Provision of all types of construction services through booking form and application form
                        provided by the Company on the Platform.</li>
                    <li>Provision of all types of engineering services through booking form and application form
                        provided by the Company on the Platform.</li>
                    <li>Provision of all types of repair and maintenance services through booking form and application
                        form provided by the Company on the Platform.</li>
                    <li>Provision of all types of building health services through booking form and application form
                        provided by the Company on the Platform.</li>
                    <li>Providing discount coupons or promotional offers to Client(s) as We shall deem fit at Our sole
                        discretion subject to the terms of such discount coupons or promotional offers and</li>
                    <li>Any other services We may deem fit.</li>
                    <li>(Collectively referred to as “Services”)</li>
                </ul>
                <p>We may, from time to time, release new features on the Website, new versions of Our Apps, or
                    introduce other Services and/or resources on the Platform. Any such amendments to the Platform will
                    be subject to these Terms as well as any additional terms of use that We may release for those
                    specific Services or features.</p>
            </div>
            <div class="item">
                <h3 class="about-small-title small-title">
                    <span class="title">ACCEPTANCE AND GOVERNANCE OF TERMS</span>
                </h3>
                <p>You hereby expressly acknowledge and agree to be bound by these terms, policies and guidelines
                    incorporated by reference in these terms, as may be amended from time to time in the manner as set
                    forth herein below (“Terms”). By using the Platform, you represent and warrant that:</p>
                <ul>
                    <li>You are at least 16 years old.</li>
                    <li>You have the lawful authority and capacity to contract and be bound by these Terms</li>
                    <li>If You are accepting these Terms on behalf of a company, limited liability partnership or other
                        legal entity, you have the authority to bind such entity to these Terms and, in such event,
                        “You” and “Your” as used in these Terms shall refer to such entity; and</li>
                    <li>You will comply with all applicable laws and regulations.</li>
                </ul>
                <p>These Terms are subject to change at any time without notice. To make sure You are aware of any
                    changes, please review these Terms periodically. Continued use of the Platform or Services after any
                    such changes shall constitute Your consent to such changes. These Terms are published in compliance
                    of and is governed by the provisions of Nepalese law.</p>
                <p>You must not:</p>
                <ul>
                    <li>Republish material from this website (including republication on another website).</li>
                    <li>Sell, rent or otherwise sub-license material from nirmansathi.com</li>
                    <li>Show any material from nirmansathi.com in public</li>
                    <li>Reproduce, duplicate, copy or otherwise exploit material on our website for a commercial
                        purpose.</li>
                    <li>Edit or otherwise modify any material on the website.</li>
                </ul>

            </div>
            <div class="item">
                <h4>1. USE OF THE SERVICE</h4>
                <ul>
                    <li>We shall provide the Services vide Our employees, agents, contractors and/or representatives
                        (“Service Professionals”), based on the requirements of the Client, at the location(s) and for
                        the term mentioned in the Book Form executed by the Client. Our qualified representatives shall
                        ensure that the Services would be provided in a professional workmanlike manner, in accordance
                        with industry standards</li>
                    <li>Discount coupons and promotional offers: The Company may at its discretion run promotional
                        offers or offer discount coupons (collectively referred to as “Coupons”) in respect of certain
                        Services subject to such terms and conditions as may be prescribed. All Coupons shall be subject
                        to the terms and conditions governing the same, as displayed on the Platform. The Client hereby
                        agrees and acknowledges that the Company shall have the sole discretion to offer Coupons, modify
                        the terms and conditions governing Coupons and discontinue the offer of Coupons with no prior
                        intimation to the Client, at its sole discretion. The Client shall have no right, claim or
                        authority to dispute the continuance or discontinuance of Coupons by the Company.</li>
                </ul>
            </div>
            <div class="item">
                <h4>2. MEMBERSHIP</h4>
                <p>It is not mandatory to register to visit, access and use the Platform. However, access to the
                    Services is only available to registered users. You need to register to avail the Services on the
                    Platform by providing, Your name, email address, password and other details. By registering and
                    creating Your Account on the Platform, you agree to:</p>
                <ul>
                    <li>Provide accurate, current and complete information as may be prompted (“Registration Data”) and
                        maintain and undertake to update the same in respect of any changes.</li>
                    <li>Maintain the security of Your password, if any</li>
                    <li>Accept all risks of unauthorized access to the Registration Data and any other information You
                        provide to Us</li>
                    <li>Notify us immediately of any breach of security or any unauthorized use of Your Account</li>
                    <li>Not authorize, assign or otherwise transfer Your Account to any other person or entity.</li>
                    <li>Operate through the account created and in no event use another user’s account for any purpose
                        or objective and</li>
                    <li>Be responsible for all activity on Your Account and to use and operate the same in accordance
                        with applicable law.</li>
                </ul>
                <p>We reserve the right to disallow, cancel, remove or reassign certain usernames and permalinks in
                    appropriate circumstances, as determined by Us in Our sole discretion, and may, with or without
                    prior notice, suspend or terminate Your Account if activities occur on that Account which, in our
                    sole discretion, would or might constitute a violation of these Terms, or a contravention or
                    violation of the rights of any third party, or of any applicable laws or regulations. We may at its
                    sole discretion restore access to Your Account, at any time, pursuant to any suspension.</p>
            </div>
            <div class="item">
                <h4>3. PAYMENTS</h4>
                <ul>
                    <li>In consideration for the Services, you agree and acknowledge that You shall pay to the Company,
                        a service fee for each service conducted through this platform in the manner as set forth in the
                        Book Form. The Company shall be entitled to amend the fees and charges leviable for Services,
                        anytime at its sole discretion.</li>
                    <li>There will not be any charges for booking for services and registering for careers.</li>
                    <li>If You have paid for the Services and have cancelled the purchase transaction, prior to
                        provision of the Services by the Company, in the manner and in accordance with the procedure set
                        forth in the Book Form, the Company shall be entitled to deduct as a convenience fee for
                        processing the refund of the Service Fee and shall refund the remaining amount. You agree and
                        acknowledge that deduction of such convenience fee shall be in addition to any fees that may be
                        deducted/levied by the Payment Processors (as defined hereinbelow) for processing such refund.
                    </li>
                    <li>The Service Fee shall be exclusive of all applicable taxes and any taxes in respect of the
                        Service Fee as may be payable under applicable law shall be the responsibility of the Client and
                        not the Company.
                    </li>
                    <li>The Client represents and warrants that any credit or payment information it supplies is true,
                        accurate and complete in all respects. In the event the Client commits a breach in respect
                        thereof, the Company reserves its right to either suspend the Services with immediate effect or
                        terminate Your access to the Platform.
                    </li>
                    <li><b>Refund Policy</b>: Once the Services have been provided to the Client in accordance with
                        the
                        Book Form, the Client shall not be entitled to a refund of the Service Fee under any
                        circumstances whatsoever.
                    </li>
                    <li>The Client agrees and acknowledges that the Company uses third-party payment processors
                        (“Payment Processors”) for any payments made on the Platform. The processing of all payments
                        will be subject to the terms, conditions and privacy policies of the Payment Processors in
                        addition to these Terms. The Client further agrees and acknowledges that neither the Company nor
                        any of its directors, shareholders or other representatives shall be liable to the Client under
                        any circumstances for any direct, indirect, punitive, incidental, special or consequential
                        damages that result from or arise out of use of the Payment Processors.
                    </li>
                </ul>
            </div>

            <div class="item">
                <h4>4. ACCESS AND USE</h4>
                <p>The Company grants You a limited, personal, non-exclusive, non-transferrable and non-sub licensable
                    right to use the Platform and Services, solely for Your own personal, non-commercial use, subject to
                    the Terms. Your access and use of the Platform and Services is subject to the following
                    representations and warranties:
                </p>
                <ul>
                    <li>You may only access the Platform or Services using authorized and lawful means;
                    </li>
                    <li>We shall not be liable for any failure or default to provide access to the Platform or Services
                        on account of any failure or delay by You to register with the Platform for such access or due
                        to any other reasons whatsoever;
                    </li>
                    <li>Any configuration or set up of the Devices for access to the Platform and the Services shall be
                        Your sole responsibility. “Device” means a device, usually electronic, that processes data
                        according to a set of instructions, which may include but not restricted to workstations,
                        personal computers, laptops, netbooks, personal digital assistants, tablets, and smartphones;
                    </li>
                    <li>The Company reserves the right to prevent access should You be using the Platform, Service or
                        Application with an incompatible or unauthorized Device;
                    </li>
                    <li>The Company collects, stores, processes and uses Your information in accordance with Company’s
                        Privacy Policy available at (“Privacy Policy”). By using the Platform and/ or by providing Your
                        Personal Information (as defined in the Privacy Policy), You consent to the collection and use
                        of the information You disclose on the Platform by Company in accordance with the Privacy
                        Policy;
                    </li>
                    <li>You will not take any action that interferes with, degrades or adversely affects the Company
                        and/or the Services and/or the Platform;
                    </li>
                    <li>You will not use the Platform in a manner (i) that is prohibited by any law or regulation, or
                        facilitates the violation of any law or regulation; or (ii) will disrupt a third parties’
                        similar use; (iii) violate or tamper with the security of the Platform;
                    </li>
                    <li>You will not use the Platform, or any portion thereof, to transmit, publish, post, upload,
                        distribute or disseminate any inappropriate, harassing, abusive, defamatory, libellous, obscene,
                        illegal or deceptive content;
                    </li>
                    <li>You will ensure that the Platform is not used to upload, post, transmit, or otherwise make
                        available any content that contains a virus or any other form of malicious code or data that is
                        likely or intended to have an adverse impact on, or provide unauthorized access to, the Platform
                        or any other software, hardware, services or data;
                    </li>
                    <li>You shall not use any automated system, including but not limited to, “robots,” “spiders,”
                        “offline readers,” “scrapers,” etc., to access the Platform;
                    </li>
                    <li>You will not attempt to gain unauthorised access to any accounts, Service Professionals’
                        information, computer systems or networks connected to the Platform, including but not limited
                        to, names, addresses, phone numbers, or email addresses, copying copyrighted text, through
                        hacking, or any other means, or obtain or attempt to obtain any materials or information through
                        any means not intentionally made available to You;
                    </li>
                    <li>You will not use, misuse or misappropriate the Platform to develop, or to assist anyone in
                        developing a competitive platform, service or for other competitive purposes;
                    </li>
                    <li>You will not copy, distribute, or make derivative works of the Platform in any medium and You
                        will refrain from taking any steps, such as reverse assembly or reverse compilation, to derive a
                        source code equivalent to Platform or attempt to do so, or acquiesce, authorise or encourage any
                        other party to do the same;
                    </li>
                    <li>You shall not recruit, solicit, or contact in any form Service Professionals for employment or
                        contracting for a business not affiliated with the Company;
                    </li>
                    <li>You shall be solely responsible for (i) procuring and maintaining Your network connections and
                        telecommunications links from Your systems to us, and (ii) all problems, conditions, delays,
                        delivery failures and all other loss or damage arising from or relating to Your network
                        connections or telecommunications links or caused by the internet;
                    </li>
                    <li>You acknowledge that from time to time, we may apply Upgrades (hereinafter defined) to the
                        Platform and that such Upgrades may result in changes to the appearance and/or functionality of
                        Platform. You may be required to install certain Upgrades or updates to the software to continue
                        to access or use the Platform, or portions thereof. “Upgrades” means new versions of, and
                        updates to, Platform whether for fixing an error, bug or other issues in the Platform or
                        enhancing the functionality of Platform.
                    </li>
                </ul>
            </div>

            <div class="item">
                <h4>5. FEEDBACK</h4>
                <ul>
                    <li>As a participant in the Platform, you agree to use careful, prudent, and good judgment when
                        leaving feedback for other users of the Platform. In the event the feedback violates these
                        Terms, is inappropriate or violates propriety or privacy of another user, the Company, in its
                        sole discretion, may take any of the following actions: (i) delete Your feedback or any of your
                        postings; (ii) limit your Account privileges; (iii) suspend your Account; and (iv) report to law
                        enforcement authorities any actions that may be illegal, and any reports it receives of such
                        conduct. When legally required or at the Company’s discretion, the Company will cooperate with
                        law enforcement agencies in any investigation of alleged illegal activity on this Platform.
                    </li>
                    <li>Reporting inappropriate use of feedback: You may contact the Company regarding any inappropriate
                        use of feedback via our contact details.
                    </li>
                    <li>Resolving disputes in connection with feedback: In the event, any dispute arises between users
                        of the Platform concerning the feedback, the Company shall be the final arbiter of such dispute
                        and shall take any action it deems reasonable including the actions without incurring any
                        liability therefrom.
                    </li>
                    <li>The Company does not and cannot review every posting made on the Platform. These Terms do not
                        require the Company to monitor, police or remove any postings or other information submitted by
                        You or any other user and the Company shall not be responsible for any ensuing liability.
                    </li>
                </ul>
            </div>

            <div class="item">
                <h4>6. INTELLECTUAL PROPERTY RIGHTS</h4>
                <p>The trademarks, logos and service marks displayed on the Platform (“Marks”) are the exclusive
                    property of the Company. You hereby acknowledge that any and all of the intellectual property rights
                    (including but not limited to all copyright, patent, Marks, etc.) and other proprietary rights in
                    and in relation to the Platform including without limitation any derivatives, improvements or
                    modifications which ownership is directly attributable to the Company (expressly excluding any
                    information which belongs to a Client, another third party) and any suggestions, ideas, enhancement
                    requests, feedback, recommendations or other information provided by You or any other party relating
                    to the Platform or the Services shall vest wholly completely and fully with the Company throughout
                    the territory of the world and You shall have no right or claim to such intellectual property in any
                    manner whatsoever.

                </p>
            </div>

            <div class="item">
                <h4>7. INDEMNIFICATION</h4>
                <p>By accepting these Terms and using the Platform, You agree that You shall defend, indemnify and hold
                    the Company, its directors, shareholders, officers and other representatives harmless from and
                    against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys’
                    fees and costs) arising out of or in connection with: (i) Misuse of Your access to and use of the
                    Platform and/or the Service; (ii) any loss or injury to the Company’s representatives resulting from
                    the performance of the Services attributable to Your act or omission; (iii) Your violation or breach
                    of these Terms or any applicable law or regulation; (iv) Your violation of any rights of any third
                    party; or (vi) any and all third-party claims based upon the content of any communications
                    transmitted by You.

                </p>
            </div>

            <div class="item">
                <h4>8. DISCLAIMER OF WARRANTIES</h4>
                <ul>
                    <li>The Company hereby explicitly and specifically disclaims all representations, warranties or
                        guarantees, whether written, oral, expressed or implied including, without limiting the
                        generality of the foregoing, any warranty of merchantability, quality or fitness for a purpose.
                    </li>
                    <li>The Platform is provided strictly on an “as is” basis. Notwithstanding anything contained in
                        these Terms, the Company does not warrant that any Platform: (i) will perform error-free or
                        uninterrupted, or that the Company will correct all or any errors or defects (ii) will operate
                        in combination with the Devices, or with any other hardware, software, systems or data not
                        provided by the Company, (iii) will meet the Client’s requirements, specifications or
                        expectations or that the Services will be available at any particular time or location,
                        uninterrupted or secure.
                    </li>
                    <li>Company reserves the right at any time and from time to time to modify or discontinue,
                        temporarily or permanently, the Platform (or any part thereof) with or without notice and in its
                        sole discretion. You agree that we shall not be liable to You or to any third party for any
                        modification, suspension or discontinuance of the Platform or the Services.
                    </li>
                    <li>We shall not be liable to You for any delay or failure in performance of the Services arising
                        out of a cause beyond its control and without its fault or negligence. Such causes may include
                        but are not limited to fires, floods, earthquakes, strikes, unavailability of necessary
                        utilities, blackouts, acts of God, acts of declared or undeclared war or acts of regulatory or
                        governmental agencies.
                    </li>
                    <li>The Client acknowledges that the Company does not control the transfer of data over the
                        communications facilities, including the internet, and that any Platform may be subject to
                        limitations, delays, and other problems inherent in the use of such communications facilities.
                        The Company shall not be responsible for any (i) delays, delivery failures, or other damages as
                        a result; (ii) issues related to the performance, operation or security of any Platform that
                        arises from the Client’s content or third-party content.
                    </li>
                    <li>The Company does not make any representation or warranty regarding the reliability, accuracy,
                        completeness, correctness, or usefulness of third-party content, and disclaims all liabilities
                        arising from or related to third-party content.
                    </li>
                </ul>
            </div>

            <div class="item">
                <h4>9. LIMITATION OF LIABILITY</h4>
                <p>In no event shall Nirmansathi.com or anyone else involved in administering, distributing or providing
                    the Platform be liable for any direct, special, exemplary, consequential, incidental, punitive or
                    indirect damages including without limitation damages for loss of profits, goodwill, use, data or
                    other intangible losses, that results from the use of, or inability to use the Platform and/or
                    Services. Nirmansathi.com or anyone else involved in administering, distributing or providing the
                    Platform further explicitly disclaims all liability for any the following:

                </p>
                <ul>
                    <li>errors, mistakes or inaccuracies of the content displayed on the Platform;
                    </li>
                    <li>personal injury or property damage of any nature whatsoever, resulting from Our Services;
                    </li>
                    <li>the acts or omissions of Our representatives performing Services on Our behalf;
                    </li>
                    <li>any failure or delay in the Services, including a delay of our representatives in performing the
                        Services;
                    </li>
                    <li>User content or the defamatory, offensive, or illegal conduct of any third party;
                    </li>
                    <li>viruses, computer viruses or other harmful, disabling computer code, computer instructions,
                        circuitry or other technological means whose purpose is to disrupt, damage or interfere with any
                        computer and communications facilities or equipment (“Harmful Code”) that may be transferred to
                        Your Devices when accessing the Platform. By way of clarification, Harmful Code shall include,
                        without limitation, any code containing viruses, Trojan horses, worms or like destructive code
                        or code that was intentionally written to self-replicate. You are advised to obtain and use
                        appropriate anti-virus and security software and to take all other appropriate measures to
                        safeguard the integrity of Your Devices.
                    </li>
                </ul>
                <p>We shall not be liable to You or any third party for any alleged or actual damages or losses
                    resulting directly or indirectly from Your failure to remove from plain sight and/or secure all
                    valuables at the location before our representatives arrive to perform the Service or Your failure
                    to adhere to Your obligations under the Terms.

                </p>
                <p>In no event shall we or anyone else involved in administering, distributing or providing the Platform
                    and/or Services be liable to You for any claims, proceedings, liabilities, obligations, damages,
                    losses or costs for an amount exceeding the Service Fees paid by You to us for the preceding service
                    and purchase transaction.

                </p>
            </div>

            <div class="item">
                <ul>
                    <li>These Terms will continue to apply until terminated by either You or Us as set forth below.
                    </li>
                    <li>Termination by You: If You wish not to be bound by these Terms, you may terminate your
                        relationship with Us by (i) not accessing the Website; and/or (ii) deleting Your Applications,
                        Registrations, Accounts (if any).
                    </li>
                    <li>Termination by Us: We may at its discretion and at any time with or without notice, terminate or
                        suspend the Terms, with or without cause if:
                    </li>
                    <li>You breach any of the provisions of the Terms, the Privacy Policy or any other terms,
                        conditions, or policies that may be applicable to You;
                    </li>
                    <li>Nirmansathi.com is required to do so in accordance with law; or
                    </li>
                    <li>Nirmansathi.com has elected to discontinue, with or without reason, access to the Platform (or
                        any part thereof) either in general or specifically to You.
                    </li>
                    <li>Nirmansathi.com shall not be liable to You or any third party for any such termination
                    </li>
                </ul>
                <h4>Consequences of Termination:</h4>
                <ul>
                    <li>Once Your Account has been terminated, all content will be irretrievably deleted by us, except
                        to the extent that we are obliged to maintain or permitted to retain in accordance with law;
                    </li>
                    <li>All reward points, offers and discounts accrued to Your Account shall automatically terminate;
                    </li>
                    <li>The permission granted to You in terms of these Terms shall stand terminated effective
                        immediately;
                    </li>
                    <li>The Company, in its sole discretion, may initiate appropriate legal proceedings against You, if
                        necessary;
                    </li>
                    <li>Termination shall not affect Your liability or obligations arising prior to such termination and
                        all amounts payable by You pursuant to access or use of the Platform shall become immediately
                        due and payable.
                    </li>
                </ul>
            </div>

            <div class="item">
                <h4>11. GENERAL</h4>
                <ul>
                    <li>To the extent that anything in or associated with the Platform is in conflict or inconsistent
                        with these Terms, these Terms shall take precedence and prevail. Our failure to enforce any
                        provision of these Terms shall not be deemed a waiver of such provision nor of the right to
                        enforce such provision. Our rights under these Terms shall survive any discontinuance of the
                        access or use of the Platform;
                    </li>
                    <li>These Terms together with the Book Form, Registration form, Privacy Policy published by us on
                        the Platform, shall constitute the entire agreement between You and Us concerning the Platform;
                    </li>
                    <li>If any provision of these Terms is held to be invalid or unenforceable, such provision shall be
                        struck and the remaining provisions shall fully be enforced under the law;
                    </li>
                    <li>Nirmansathi.com nor You shall be liable for any delay or failure in performance under the Terms,
                        other than payment obligations, arising out of a cause beyond its control and without its fault
                        or negligence. Such causes may include but are not limited to fires, floods, earthquakes,
                        strikes, and unavailability of necessary utilities, acts of God, war, acts of regulatory
                        agencies, or other natural disasters.
                    </li>
                </ul>
            </div>

            <div class="item">
                <h4>11. DISPUTE RESOLUTION AND GOVERNING LAW</h4>
                <ul>
                    <li>If there is a concern in respect of these Terms or the Services provided by the Company, you are
                        requested to Contact Us. Any concern shall be sought to be addressed by the Company and in the
                        event of any dispute, You and the Company shall endeavour to amicably resolve the dispute.
                    </li>
                    <li>These Terms shall be governed and construed under the laws of Nepal.
                    </li>
                </ul>
            </div>




    </div>
        )
}
