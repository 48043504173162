import React from 'react';
import './App.css';
import {Route, Switch, Redirect} from 'react-router-dom';
import Navbar from './components/Navbar.js';
import Home from './pages/Home.js';
import Footer from './components/Footer.js';
import About from './pages/About.js';
import ServiceDetail from './pages/ServiceDetail.js';
import ServicePage from './pages/ServicePage.js';
// import Login from './pages/Login.js';
// import Register from './pages/Register.js';
// import Dashboard from './pages/Dashboard.js';
// import fire from './Firebase.js';
import Privacyandpolicy from './pages/privacyandpolicy.js';
import Termsandconditions from './pages/termsandconditions.js';
import 'bootstrap/dist/css/bootstrap.min.css';



class App extends React.Component{
    render(){
  return (
    <div className="App">
    <Navbar />
    <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/about" exact component={About} />
    <Route path="/service" exact component={ServicePage} />
    <Route path="/service/:id" exact component={ServiceDetail} />
    <Route path="/privacy-policy" exact component={Privacyandpolicy} />
    <Route path="/terms-and-conditions" exact component={Termsandconditions} />
    <Redirect to="/" />
    </Switch>
    <Footer />
    </div>
  );
}
}

export default App;

