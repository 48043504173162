import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyC4t4nc-USIKD5FVWyq1jk8qjgNtxoyNPc",
  authDomain: "nirman-sathi-7e9fd.firebaseapp.com",
  projectId: "nirman-sathi-7e9fd",
  storageBucket: "nirman-sathi-7e9fd.appspot.com",
  messagingSenderId: "616461922826",
  appId: "1:616461922826:web:0c59bb77cc35c850f5e5f3"
  };

const fire = firebase.initializeApp(firebaseConfig);


export default fire;
