import React from 'react';
import './components.css';
import {Link} from 'react-router-dom';

      // <div className="rightCol">
      //   <div className="rightCol-item">
      //       {subservicelist.map((ind,data) => (
      //           <li key={ind}>{data.item}</li>
      //       ))}
      //   </div>
      //   </div>

//   <li class="list-group-item active">Cras justo odio</li>







const SubService = ({subservicelist}) =>{
    return (
        <div className="rightCol-item serviceList">
            {subservicelist.map((data,ind) => (
              <>
              <Link style={{textDecoration:"none"}} to={`/service/${data.id}`} key={ind}>{data.item}</Link>
              </>
            ))}
        </div>
    )
}

export default SubService;



