import React from 'react';
import './components.css';

function WhyNirman(props) {
    return(
        <div className="col-10 mx-auto col-md-6 col-lg-2 my-3">
            <figure className="figure">
              <img src={props.img} className="figure-img img-fluid rounded" alt="fig" height="80px" width="80px"/>
              <figcaption className="figure-caption text-dark">
              <b><h6>{props.title}</h6></b>
              </figcaption>
            </figure>
        </div>
        )
}


export default WhyNirman;
