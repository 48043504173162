import React from 'react';
import './components.css';
import {NavLink,Link} from 'react-router-dom';


const Navbar = () => {
    return(
        <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{backgroundColor:"white", fontSize:"20px"}}>
          <Link to="/"><img className="navbar-brand ml-lg-5 ml-sm-2" src="/pictures/logo.png" alt="logo" width="150px" /></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ml-auto mt-2 mr-5 mt-lg-0 pr-4">
              <li className="nav-item text-center mx-auto">
                <NavLink exact to="/" data-toggle="collapse" data-target=".navbar-collapse.show" activeClassName="selected"  className="nav-link text-dark">Home</NavLink>
              </li>
              <li className="nav-item text-center mx-auto">
                <NavLink exact to="/service" data-toggle="collapse" data-target=".navbar-collapse.show" activeClassName="selected"  className="nav-link text-dark">Services</NavLink>
              </li>
              <li className="nav-item text-center mx-auto">
                <NavLink exact to="/about" data-toggle="collapse" data-target=".navbar-collapse.show" activeClassName="selected"  className="nav-link text-dark">About us</NavLink>
              </li>
            </ul>
          </div>
        </nav>
        )
}

export default Navbar;

